


































import { Component, Vue } from 'vue-property-decorator';

import specialRequestsQuery from '@/graphql/me/MeSpecialRequests.query.gql';

import BackHeader from '@/components/partials/BackHeader.vue';
import AddSpecRequestForm from '@/components/partials/profile/AddSpecRequestForm.vue';
import UserSpecialRequest from '@/interfaces/UserSpecialRequest';
import UserSpecialRequestsListItem from '@/components/UserSpecialRequestsListItem.vue';

const DATA_PER_PAGE = 5;

@Component({
  name: 'AddSpecRequest',
  components: { UserSpecialRequestsListItem, BackHeader, AddSpecRequestForm },
  metaInfo: {
    title: 'Special requests',
  },
  apollo: {
    me: {
      fetchPolicy: 'no-cache',
      query: specialRequestsQuery,
      variables() {
        return {
          limit: DATA_PER_PAGE,
          page: this.page,
        };
      },
      result(result: any) {
        this.fetchingRequests = false;
        this.specialRequests.push(...result.data.me.specialRequests.data);
        this.hasMoreSpecialRequests = result.data.me.specialRequests.hasMorePages;

        if (this.page === 1 && !this.specialRequests.length) {
          this.showRequestForm = true;
        }
      },
    },
  },
})
export default class AddSpecRequest extends Vue {
  fetchingRequests = true;
  page = 1;
  specialRequests: UserSpecialRequest[] = [];
  hasMoreSpecialRequests = false;
  showRequestForm = false;

  loadMore() {
    this.page++;
    this.fetchingRequests = true;
  }

  handleAddNewRequest(newRequest: UserSpecialRequest) {
    this.specialRequests.unshift(newRequest);
    this.showRequestForm = false;
  }
}
