







import { Component, Prop, Vue } from 'vue-property-decorator';
import UserSpecialRequest from '@/interfaces/UserSpecialRequest';
import { formatDate } from '@/helpers/dateTimeHelper';

@Component({
  name: 'UserSpecialRequestsListItem',
})
export default class UserSpecialRequestsListItem extends Vue {
  @Prop() data: UserSpecialRequest;

  get requestDate() {
    return formatDate(this.data.created_at);
  }
}
